import React from 'react';
import './BillList.css';

class BillList extends React.Component {
  constructor() {
    super();

    this.state = {
      data: [],
      loading: true,
      error: null,
      index: 0,
      count: 18,
      numberOfRecords: 0,
      buttonFormat: 0
    };

    this.nextButton = this.nextButton.bind(this);
    this.prevButton = this.prevButton.bind(this);
    this.getNumberOfRecords = this.getNumberOfRecords.bind(this);
    this.changeFormat = this.changeFormat.bind(this);
  }

  componentDidMount() {
    this.getNumberOfRecords(); // Fetch total number of records first
    this.fetchData(); // Then fetch paginated data
  }

  fetchData(){
    var {index, count} = this.state

    fetch(`https://dashboardapi.plurato.com/getbilllist?companyid=1&year=2024&sortby=billID&sortasc=2&index=${index}&count=${count}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      this.setState({ data, loading: false });
    })
    .catch((error) => {
      this.setState({ error: error.message, loading: false });
    });
  }

  getNumberOfRecords() {
    fetch("https://dashboardapi.plurato.com/getbilllist?companyid=1&year=2024&sortby=billID&sortasc=1&index=0&count=999999999999999")
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ numberOfRecords: data.length }); // Set numberOfRecords based on data length
      })
      .catch((error) => {
        this.setState({ error: error.message, loading: false });
      });
  }

  nextButton() {
    const { index, count, numberOfRecords } = this.state;
    if (index + count < numberOfRecords) {
      this.setState(
        (prevState) => ({
          index: prevState.index + count,
          loading: true,
        }),
        this.fetchData
      );
    }
  }

  prevButton() {
    const { index, count } = this.state;
    if (index - count >= 0) {
      this.setState(
        (prevState) => ({
          index: prevState.index - count,
          loading: true,
        }),
        this.fetchData
      );
    }
  }

  changeFormat() {
    this.setState((prevState) => {
      const newButtonFormat = prevState.buttonFormat === 0 ? 1 : 0;
      const newCount = newButtonFormat === 0 ? 18 : 11;
  
      return {
        buttonFormat: newButtonFormat,
        count: newCount,
      };
    }, () => {
      this.fetchData();
    });
  }
  

  render() {
    const { data, loading, error, buttonFormat } = this.state;
  
    // Determine class names based on buttonFormat
    const containerClass = buttonFormat === 0 ? 'button-container0' : 'button-container1';
    const buttonClass = buttonFormat === 0 ? 'billButton0' : 'billButton1';
    const sellerNameClass = buttonFormat === 0 ? 'seller-name0' : 'seller-name1';
    const totalPriceClass = buttonFormat === 0 ? 'total-price0' : 'total-price1';
    const dateClass = buttonFormat === 0 ? 'date0' : 'date1';
  
    if (loading) {
      return <p>Loading...</p>;
    }
  
    if (error) {
      return <p>Error: {error}</p>;
    }
  
    return (
      <div className="main-container">
        <button className="changeFormat" onClick={this.changeFormat}>Change Format</button>
        <div className={containerClass}>
          {data.map((item) => (
            <button
              key={item.id}
              className={buttonClass}
              onClick={() => console.log(item)}
            >
              <span className={sellerNameClass}>{item.sellerName}</span>
              <span className={totalPriceClass}>{item.totalPrice}</span>
              <span className={dateClass}>
                {item.issueDateYear} {item.issueDateMonth} {item.issueDateDay}
              </span>
            </button>
          ))}
        </div>
        <div className="pagination-controls">
          <button className="nextButton" onClick={this.prevButton} disabled={this.state.index === 0}>
            Prev
          </button>
          <button className="nextButton" onClick={this.nextButton} disabled={this.state.index + this.state.count >= this.state.numberOfRecords}>
            Next
          </button>
        </div>
      </div>
    );
  }  
}

export default BillList;
