import React  from 'react';
import './App.css';
import AppLang from './AppLang.json';

class About extends React.Component {

	constructor(props) {

		super(props);

		this.renderOneLine = this.renderOneLine.bind(this);
	}
	
	renderOneLine(dateString, infoText) {
		
		return (
			<div>
				<div className="AboutDateString">{dateString}</div>
				<div className="AboutInfoText">{infoText}</div>
				<div style={{height: "15px"}}></div>
			</div>
		);
	}

	render() {
		return (
			<div>
				<div style={{height: "20px"}}></div>
				<div className="AboutTitle">{this.props.getLang(AppLang, "about")}</div>
				<div style={{height: "20px"}}></div>
				{this.renderOneLine("28.08.2024.", "Development started - Frane and Ana")}
			</div>
		);
	}
}

export default About;

